import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
// import './index.css';
import { Button, message, Input, Select, Collapse } from 'antd';
import './index.css';
// import questionBlocks from './questions'
import creService from "../../services/creService";
import { useHistory } from "react-router";
const { Panel } = Collapse;

const getCurrentStep = (path, questions) => {
    let finishStep
    for (let key of path) {
        if (finishStep) {
            finishStep = finishStep[key]
        } else {
            finishStep = questions[key]
        }
    }
    return finishStep
}

const getNextStep = (currentPath, questions, answerSubkey, moveBack) => {
    if (!currentPath.length) {
        return currentPath
    }
    const currentQuestion = getCurrentStep(currentPath, questions)
    if (moveBack) {
        const backpath = [...currentPath.slice(0, -1), currentPath[currentPath.length - 1] - 1]
        const priviousQuestion = getCurrentStep(backpath, questions)
        if (priviousQuestion) {
            return backpath
        } else {
            return backpath.slice(0, -2)
        }

    } else {
        if (answerSubkey) {
            if (currentQuestion[answerSubkey]) {
                return [...currentPath, answerSubkey, 0]
            }
            if (currentQuestion.sub) {
                return [...currentPath, 'sub', 0]
            }
        }


        const nextpath = [...currentPath.slice(0, -1), currentPath[currentPath.length - 1] + 1]

        const nextQuestion = getCurrentStep(nextpath, questions)
        if (nextQuestion) {
            return nextpath
        } else {
            return getNextStep(currentPath.slice(0, -2), questions)
        }

    }
}

const QuestionsBlock = ({ block, path, addAnswer, addQuestion, refresh }) => {

    return <div >
        <Button className='mb-2' onClick={() => { addQuestion(path) }}>
            Add Question
        </Button>
        <Collapse collapsible="icon">
            {
                block.map((blockQuestion, iq) => {
                    return <Panel header={
                        <div onClick={(event) => event.stopPropagation()}>
                            <strong>Question ({iq + 1}):</strong>
                            <Input className='m-2'
                                style={{ width: '600px' }}
                                value={blockQuestion.question}
                                onChange={(e) => { blockQuestion.question = e.currentTarget.value; refresh() }}

                            />
                            <i
                                className="fa fa-trash"
                                onClick={(e) => { block.splice(iq, 1); refresh() }}
                            />
                        </div>
                    } key={path + `Question${iq + 1}`}
                    >
                        <Collapse collapsible="icon">
                            {blockQuestion.answers.map((answer, i) => {
                                return <Panel header={
                                    <div onClick={(event) => event.stopPropagation()}>
                                        <strong>Answer ({i + 1}):</strong>
                                        <Input
                                            style={{ width: '600px' }}
                                            className='m-2'
                                            value={answer}
                                            onChange={(e) => { blockQuestion.answers[i] = e.currentTarget.value; refresh() }}
                                            disabled={answer === 'client input' || answer === 'client date'}
                                        />
                                        <i
                                            className="fa fa-trash"
                                            onClick={(e) => { blockQuestion.answers.splice(i, 1); refresh() }}
                                        />
                                        <div className='mb-1'>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] !== 'client date' && blockQuestion.answers[i] !== 'client input' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'new answer'; refresh() }}>
                                                Preload Answer
                                            </button>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] === 'client date' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'client date'; refresh() }}>
                                                Date picker
                                            </button>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] === 'client input' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'client input'; refresh() }}>
                                                Free Input
                                            </button>
                                        </div>
                                    </div>
                                }
                                    key={path + `Answer${i + 1}`}
                                >

                                    {
                                        blockQuestion[answer + 'Sub'] ?
                                            <QuestionsBlock refresh={refresh} block={blockQuestion[answer + 'Sub']} path={[...path, iq, answer + 'Sub']} addAnswer={addAnswer} addQuestion={addQuestion} /> :
                                            <Button className='mb-2' onClick={() => { addQuestion([...path, iq, answer + 'Sub']) }}>
                                                Add Question
                                            </Button>
                                    }
                                </Panel>
                            })}


                        </Collapse>
                        <Button className='mt-2' onClick={() => { addAnswer([...path, iq, 'answers']) }}>
                            Add Answer
                        </Button>
                    </Panel>
                })
            }

        </Collapse>
    </div>
}

const App = ({ location }) => {
    const [adminjwt, setAdminjwt] = useState('');
    const [questions, setQuestions] = useState();
    const [currentPath, setCurrentPath] = useState([]);
    const [currentStep, setCurrentStep] = useState({});
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const history = useHistory()

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);

        let token = queryParams.get('token')

        if (token) {
            localStorage.setItem('adminjwt', JSON.stringify(token));
        } else {
            token = localStorage.getItem('adminjwt');
        }
        history.replace({
            search: '',
        })
        setAdminjwt(token)

        getQuestinary(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (currentPath.length) {
            setCurrentStep(getCurrentStep(currentPath, questions))
        }


    }, [currentPath, questions])

    useEffect(() => {
        if (shouldUpdate) {
            const delayDebounceFn = setTimeout(() => {
                updateQuestinary(questions)
            }, 2000);

            return () => clearTimeout(delayDebounceFn);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);



    const getQuestinary = async (token) => {
        try {
            const result = await creService.getInspectionQuestioners(token)
            if (!result.data.status) {
                return toast.error(result.data.message);
            }
            setQuestions(result.data.data)
            setShouldUpdate(true)
        } catch (err) {
            toast.error(err.message);
        }
    }

    const updateQuestinary = async () => {

        try {
            const result = await creService.updateInspectionQuestioners(questions, adminjwt)
            if (!result.data.status) {
                return toast.error(result.data.message);
            }
        } catch (err) {
            toast.error(err.message);
        }

    }

    const next = (answer) => {

        const currentQuestion = getCurrentStep(currentPath, questions)
        if (answer) {
            currentQuestion.resultAnswer = answer
            setQuestions([...questions]) // to trigger update
        }
        const answerSubkey = ((answer || currentQuestion.resultAnswer) + 'Sub')
        const nextPath = getNextStep(currentPath, questions, answerSubkey)
        if (nextPath.length > 1) {

            setCurrentPath(nextPath)
        } else {
            message.success('Processing complete!')
        }



    };

    const prev = () => {
        const backPath = getNextStep(currentPath, questions, undefined, true)

        if (backPath.length > 1) {
            setCurrentPath(backPath)
        } else {
            message.error('No privious questions!')
        }

    };

    const addQuestion = (path) => {

        const currentArray = getCurrentStep(path, questions)
        if (currentArray) {
            currentArray.push({
                question: 'New question',
                answers: [],
            })
        } else {
            const privious = getCurrentStep(path.slice(0, -1), questions)
            privious[path[path.length - 1]] = [{
                question: 'New question',
                answers: [],
            }]
        }
        setQuestions([...questions]) // to trigger update

    };
    const addAnswer = (path) => {
        const currentArray = getCurrentStep(path, questions)
        if (currentArray) {
            currentArray.push('new answer')
        } else {
            const privious = getCurrentStep(path.slice(0, -1), questions)
            privious[path[path.length - 1]] = ['new answer']
        }
        setQuestions([...questions]) // to trigger update

    };
    const refresh = () => {
        setQuestions([...questions]) // to trigger update
    }


    return (
        questions ?
            <>
                <div>
                    <h3>Questions Blocks</h3>
                    <Collapse >
                        {questions.map((questionBlock, i) => {
                            return <Panel header={<div onClick={(event) => event.stopPropagation()}>
                                <Input
                                    style={{ width: '600px' }}
                                    className='m-2'
                                    value={questionBlock.blockName}
                                    onChange={(e) => {
                                        questionBlock.blockName = e.target.value
                                        refresh()
                                    }
                                    }
                                />
                                <i
                                    className="fa fa-trash"
                                    onClick={(e) => { setQuestions(questions.filter((block, index) => index !== i)) }}
                                />
                            </div>
                            } key={'questionsHeader' + i}>
                                <QuestionsBlock
                                    block={questions[i].questions}
                                    path={[i, 'questions']}
                                    addQuestion={addQuestion}
                                    addAnswer={addAnswer}
                                    refresh={refresh}
                                />
                            </Panel>
                        })}
                    </Collapse>
                    <Button className='mt-2' onClick={() => {
                        setQuestions([...questions, { blockName: 'New Block', questions: [] }])
                    }}>
                        Add Question Block
                    </Button>
                </div >
                <div className='d-flex justify-content-center ' >

                    <div className='w-50 '>
                        <Select
                            defaultValue={currentPath[0]}
                            placeholder='select question block'
                            style={{ fontSize: '21px', minWidth: '300px' }}
                            onChange={(value) => { setCurrentPath([value, 'questions', 0]) }}
                            options={questions.map((value, i) => ({
                                value: i,
                                label: value.blockName,
                                disabled: !value.questions.length
                            })
                            )}
                        />

                        <div className="steps-content d-flex align-items-center justify-content-center flex-column">

                            <h3>{currentStep.question}</h3>
                            <div className='mt-3 w-100'>
                                {currentStep.answers?.map((answer, i) => {
                                    if (answer === 'client input') {
                                        return <Input.Group compact key={currentStep.question + i}>
                                            <Input key={currentStep.question + i} style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} />
                                            <Button type="primary" onClick={(event) => next(event.target.parentElement.parentElement.children[0].value)} >Submit</Button>
                                        </Input.Group>
                                    } else if (answer === 'client date') {
                                        return <Input.Group compact key={currentStep.question + i}>
                                            <Input key={currentStep.question + i} style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} type="date" />
                                            <Button type="primary" onClick={(event) => next(event.target.parentElement.parentElement.children[0].value)} >Submit</Button>
                                        </Input.Group>
                                    } else {
                                        return <Button key={answer + i} className='ml-2' type={currentStep.resultAnswer === answer && `primary`} onClick={() => next(answer)} >{answer}</Button>
                                    }
                                })
                                }
                            </div>
                        </div>
                        {currentPath.length !== 0 &&
                            <div className="steps-action">

                                <Button type="primary" onClick={() => next()}>
                                    Next
                                </Button>
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Previous
                                </Button>

                            </div>
                        }
                    </div>
                </div>
                <ToastContainer />
            </> : null
    );
};

export default App;