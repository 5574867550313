import React, {useState} from "react";

import ReactLoading from "react-loading";

import Helper from '../../services/helperService';
import VPUSign from '../../common/VPUSign'
import { Collapse } from 'antd';
import './dashboardDetailsList.css'
const { Panel } = Collapse;

const DashboardDetailsList = ({ tableData, onClick, sortField, category, sub, handleSort, doneLoading }) => {


    const displayItemFunction = (value, key) => {
        if (
            category ===
            'Outstanding Fines'
        ) {
            let balance_due = 0;
            let overPaidAmount = 0;
            for (let violation of (value.violationsDetails )) {
                overPaidAmount +=
                    parseFloat(
                        violation.violationData.paid_amount
                    ) -
                    parseFloat(
                        violation.violationData.penalty_imposed
                    );
                balance_due += parseFloat(
                    violation.violationData.balance_due
                );
            }
            return (
                <tr
                    key={key}
                    className="pointer"
                    onClick={() => onClick(value)}
                >
                    <td>{value.fullAddress}</td>
                    <td>{value.violationsDetails.length}</td>
                    <td>
                        {Helper.formatter.format(balance_due)}
                        {sub ===
                            'Overpaid' && (
                                <span className="badge badge-danger m-l-5">
                                    {' '}
                                    Over Paid:{' '}
                                    {Helper.formatter.format(
                                        overPaidAmount
                                    )}
                                </span>
                            )}
                    </td>
                </tr>
            );

        } else {
            return (
                <tr
                    key={key}
                    className="pointer"
                    onClick={() => onClick(value)}
                >
                    <td>
                        <i className="fa fa-building"></i>{' '}
                        {value.fullAddress}
                    </td>
                    <td>
                        {value.violationsDetails?.length || 1}
                    </td>
                    {sub === 'HPD' && (
                        <td>
                            <VPUSign VPU={value.VPU} VPUHistory={value.VPUHistory} />
                        </td>
                    )}
                </tr>
            );
        }
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const handleCollapseChange = (key) => {
        setIsExpanded(key.length > 0); // If any panel is expanded, key will be non-empty.
    };


    return (
        <div className="card">
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    Address
                                    <i
                                        className={`ml-2 fa fa-sort-alpha-${sortField?.Address === -1 ? 'desc' : 'asc'
                                            }`}
                                        onClick={() => handleSort('Address')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </th>
                                <th scope="col" className='text-center'>
                                    Open
                                    <i
                                        className={`fa ml-1 fa-sort-numeric-${sortField?.Open === -1 ? 'desc' : 'asc'
                                            }`}
                                        onClick={() => handleSort('Open')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </th>
                                {category ===
                                    'Outstanding Fines' && (
                                        <th scope="col">Balance Due</th>
                                    )}
                                {sub === 'HPD' && (
                                    <th scope="col" className='text-center'>
                                        VPU
                                        <i
                                            className={`fa ml-1 fa-sort-numeric-${sortField?.Open === -1 ? 'desc' : 'asc'
                                                }`}
                                            onClick={() => handleSort('VPU')}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {!doneLoading ? (
                                <tr>
                                    <td colSpan="5">
                                        <div align="center">
                                            {' '}
                                            <ReactLoading
                                                type={'cylon'}
                                                color={'grey'}
                                            />{' '}
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                tableData?.slice(0, 3).map?.(displayItemFunction)


                            )}
                        </tbody>
                    </table>
                    {tableData?.slice(0, 3).length !== 0 &&
                        <Collapse ghost onChange={handleCollapseChange}>
                            <Panel header={isExpanded ? null : "... Show more ..."} key="1" >
                                <table className="table">
                                    <tbody>
                                        {tableData?.slice(3).map?.(displayItemFunction)}
                                    </tbody>
                                </table>
                            </Panel>

                        </Collapse>}

                </div>
            </div>
        </div >
    );
};

export default DashboardDetailsList;
